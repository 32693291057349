// utils/exportToExcel.js

import * as XLSX from 'xlsx';

export const exportToExcel = (data, fileName = 'export.xlsx') => {
  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Create a new worksheet
  const ws = XLSX.utils.json_to_sheet(data);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Write the workbook to a file
  XLSX.writeFile(wb, fileName);
};

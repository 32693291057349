import styles from './GameTable.module.css';
import { exportToExcel } from './utils';
import React from 'react';

export default function PromotionsTable({ casino }) {
  const handleExportClick = () => {
    const rowData = {
      casino: casino.name,
    };
    casino.data.promotions.list.forEach((promotion, index) => {
      rowData[`promotion ${index + 1}`] = promotion;
    });

    exportToExcel([rowData], 'casino_promotions_data.xlsx');
  };

  return (
    <div className={styles.promotionsTable}>
      <div
        className={styles.export}
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <button onClick={handleExportClick}>Export promotions</button>
      </div>
      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>Casino</th>
            {casino.data.promotions.list.map((str, index) => (
              <td>Promotion {index + 1}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{casino.name}</td>
            {casino.data.promotions.list.map((str, index) => (
              <td>{str}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

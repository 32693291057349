import React from 'react';

const GameRow = React.memo(({ casino, game }) => (
  <tr>
    <td data-label="Casino">{casino.name}</td>
    <td data-label="Game">{game.name}</td>
    <td data-label="RTP">{game.rtp}</td>
    <td data-label="Lines">{game.lines}</td>
    <td data-label="Image">
      <img src={game.image} alt={game.name} width="50" />
    </td>
    <td data-label="Provider">{game.provider.name}</td>
    <td data-label="Categories">{game.categories.join(',')}</td>
  </tr>
));
export default GameRow;
